var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contact-form-wrapper p-md-5"},[_c('div',{staticClass:"row p-5",staticStyle:{"margin-right":"15px"}},[_c('div',{staticClass:"col-lg-4 contact-header"},[_c('div',{staticClass:"contact-form-header"},[_vm._v(_vm._s(_vm.$t("contactForm.header")))])]),_c('div',{staticClass:"postcontent col-lg-7 offset-lg-1"},[(_vm.isSendButtonTouched)?_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"col-md-12 mb-auto sentMessageInfo"},[_vm._v(" "+_vm._s(_vm.afterSendMessage)+" ")]),_c('div',{staticClass:"row right mb-2"},[_c('div',{staticClass:"offset-sm-10 col-sm-2 back-button"},[_c('button',{staticClass:"btn btn-outline-light mt-3",attrs:{"type":"button"},on:{"click":_vm.goBack}},[_c('span',[_vm._v(_vm._s(_vm.$t("genericForm.back")))])])])])]):_vm._e(),(!_vm.isSendButtonTouched)?_c('form',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputName"}},[_vm._v(_vm._s(_vm.$t("contactForm.name")))]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactFormGroup.props.name),expression:"contactFormGroup.props.name"}],staticClass:"form-control",attrs:{"type":"text","id":"inputName"},domProps:{"value":(_vm.contactFormGroup.props.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactFormGroup.props, "name", $event.target.value)}}}),_vm._l((_vm.contactFormGroup.controls.name.errorMessages),function(message){return _c('div',{key:message},[(
                  _vm.contactFormGroup.controls.name.invalid && _vm.contactFormGroup.controls.name.dirty
                )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(message)+" "),_c('br')]):_vm._e()])})],2)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputEmail"}},[_vm._v(_vm._s(_vm.$t("contactForm.email")))]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactFormGroup.props.email),expression:"contactFormGroup.props.email"}],staticClass:"form-control",attrs:{"type":"email","id":"inputEmail"},domProps:{"value":(_vm.contactFormGroup.props.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactFormGroup.props, "email", $event.target.value)}}}),_vm._l((_vm.contactFormGroup.controls.email.errorMessages),function(message){return _c('div',{key:message},[(
                  _vm.contactFormGroup.controls.email.invalid && _vm.contactFormGroup.controls.email.dirty
                )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(message)+" "),_c('br')]):_vm._e()])})],2)]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputMessage"}},[_vm._v(_vm._s(_vm.$t("contactForm.message")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactFormGroup.props.message),expression:"contactFormGroup.props.message"}],staticClass:"form-control",attrs:{"id":"inputMessage","rows":"7"},domProps:{"value":(_vm.contactFormGroup.props.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactFormGroup.props, "message", $event.target.value)}}}),_vm._l((_vm.contactFormGroup.controls.message.errorMessages),function(message){return _c('div',{key:message},[(
                _vm.contactFormGroup.controls.message.invalid &&
                _vm.contactFormGroup.controls.message.dirty
              )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(message)+" "),_c('br')]):_vm._e()])})],2),_c('div',{staticClass:"form-group row gdprButton"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactFormGroup.props.gdpr),expression:"contactFormGroup.props.gdpr"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"gdprCheck"},domProps:{"checked":Array.isArray(_vm.contactFormGroup.props.gdpr)?_vm._i(_vm.contactFormGroup.props.gdpr,null)>-1:(_vm.contactFormGroup.props.gdpr)},on:{"change":function($event){var $$a=_vm.contactFormGroup.props.gdpr,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contactFormGroup.props, "gdpr", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contactFormGroup.props, "gdpr", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contactFormGroup.props, "gdpr", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"gdprCheck"}},[_vm._v(" "+_vm._s(_vm.$t("contactForm.gdpr"))+" ")])]),_vm._l((_vm.contactFormGroup.controls.gdpr.errorMessages),function(message){return _c('div',{key:message},[(
                  _vm.contactFormGroup.controls.gdpr.invalid && _vm.contactFormGroup.controls.gdpr.dirty
                )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(message)+" "),_c('br')]):_vm._e()])})],2)]),_c('div',{staticClass:"form-group row d-flex justify-content-end"},[_c('div',{staticClass:"mt-4 mr-4"},[_c('button',{staticClass:"send-button",attrs:{"type":"button","disabled":!_vm.contactFormGroup.valid},on:{"click":_vm.sendForm}},[_c('span',[_vm._v(_vm._s(_vm.$t("contactForm.sendButton")))])])])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }