import { required, email, pattern } from '@rxweb/reactive-forms';

export default class GenericContactModel {
  private _name!: string;

  private _message!: string;

  private _email!: string;

  private _gdpr!: boolean;

  constructor() {
    this._gdpr = false;
  }

  @required()
  set name(value: string) {
    this._name = value;
  }

  get name(): string {
    return this._name;
  }

  @required()
  set message(value: string) {
    this._message = value;
  }

  get message(): string {
    return this._message;
  }

  @required()
  @email()
  set email(value: string) {
    this._email = value;
  }

  get email(): string {
    return this._email;
  }

  @required()
  @pattern({ expression: { onlyTrue: /^true/ }, messageKey: 'gdpr' })
  set gdpr(value: boolean) {
    this._gdpr = value;
  }

  get gdpr(): boolean {
    return this._gdpr;
  }
}
