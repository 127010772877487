
import { Component, Vue } from 'vue-property-decorator';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms';
import * as mailStore from '@/store/modules/mail';
import * as appStore from '@/store/modules/app';
import { MailTargetType } from '@/store/modules/enums';
import GenericContactModel from '../../models/genericContact.model';

@Component({})
export default class ContactForm extends Vue {
  contactFormGroup: IFormGroup<GenericContactModel>;

  formBuilder: RxFormBuilder = new RxFormBuilder();

  isSendButtonTouched: boolean;

  afterSendMessage: string;

  mailStore = mailStore;

  appState = appStore;

  constructor() {
    super();
    this.contactFormGroup = this.formBuilder.formGroup(
      GenericContactModel,
    ) as IFormGroup<GenericContactModel>;
    this.isSendButtonTouched = false;
    this.afterSendMessage = '';
  }

  goBack() {
    this.contactFormGroup = this.formBuilder.formGroup(
      GenericContactModel,
    ) as IFormGroup<GenericContactModel>;
    this.isSendButtonTouched = false;
  }

  async sendForm() {
    const { currentLanguage } = this.appState.getters.getAppState;
    const response = await this.mailStore.actions.sendAsync({
      name: this.contactFormGroup.props.name,
      email: this.contactFormGroup.props.email,
      content: this.contactFormGroup.props.message,
      target: MailTargetType.Default,
      language: currentLanguage,
      phoneNumber: '',
      company: '',
    });

    if (response) {
      this.afterSendMessage = this.$t('genericForm.thankYou') as string;
    } else {
      this.afterSendMessage = this.$t('genericForm.sorry') as string;
    }

    this.isSendButtonTouched = true;
  }
}
